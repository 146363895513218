.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(94.61deg,#ff7656 -1.58%,#ff5252 90.92%);
}

.login-logo {
    max-width: 400px;
}

.login-form {
    min-width: 400px;
    padding-top: 16px;
    border-radius: 6px;
}

.login-form-forgot {
    float: right;
    color: white;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-input {
    border-radius: 6px;
}

.login-form-button {
    width: 100%;
    border-radius: 6px;
}